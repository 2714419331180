<template>
  <dashboard-table
      :source="source"
      :thead="selectThead"
  ></dashboard-table>
</template>

<script>
import actions from './actions';

export default {
  name: 'MineWatched',
  mixins: [actions],
  data() {
    return {
      source: 'watchers',
    };
  },
};
</script>
