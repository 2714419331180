// import DashboardTable from '../../components/DashboardTable/index.vue';

export default {
  components: {
    'dashboard-table': () => import('../../components/DashboardTable/index.vue'),
  },
  data() {
    return {
      thead: [],
      options: {
        paginate: true,
      },
    };
  },
  computed: {
    selectThead: function () {
      if (Object.keys(this.$store.state.systemSetting).includes('selectTheadIssueMyPage')) {
        let theads = [];
        this.$store.state.systemSetting.selectTheadIssueMyPage.forEach((tmpKey) => {
// iiri to jest tymczasowo aż nie zostanie zrobiony dobrze back! TODO
          if (tmpKey.startsWith('issues.')) {
            theads.push({
              label: this.$t(tmpKey),
              key: tmpKey.substring(7),
              sort: true,
            });
          }
        });
        return theads;
      }
      return [];
    },
  },
};
