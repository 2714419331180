<template>
  <dashboard-table
      :options="options"
      :source="source"
      :thead="selectThead"
  ></dashboard-table>
</template>
<script>
import actions from './actions';

export default {
  name: 'OverdueIssues',
  mixins: [actions],
  data() {
    return {
      source: 'homepage/overdue_issues',
    };
  },
};
</script>
