<template>
  <div class="view-container container-default">
    <div class="view">
      <div class="view-body p-0">
        <el-tabs
            class="custom_tabs"
            type="card"
            v-model="activeName"
        >
          <el-tab-pane
              :label="$t('system.mine_watchers')"
              name="mine_watchers"
          >
            <!--            <transition name="rowtrans">-->
            <mine-watched v-if="activeName === 'mine_watchers'" />
            <!--            </transition>-->
          </el-tab-pane>
          <el-tab-pane
              :label="$t('issues.overdueissues')"
              name="overdueissues"
          >
            <overdue-issues v-if="activeName === 'overdueissues'" />
          </el-tab-pane>
          <el-tab-pane
              :label="$t('issues.todayissues')"
              name="todayissues"
          >
            <today-issues v-if="activeName === 'todayissues'" />
          </el-tab-pane>
          <el-tab-pane
              :label="$t('issues.futureissues')"
              name="futureissues"
          >
            <future-issues v-if="activeName === 'futureissues'" />
          </el-tab-pane>
          <el-tab-pane
              :label="$t('issues.inprogress')"
              name="inprogress"
          >
            <issues-in-progress v-if="activeName === 'inprogress'" />
          </el-tab-pane>
          <el-tab-pane
              :label="$t('issues.notstarted')"
              name="notstarted"
          >
            <issues-not-started v-if="activeName === 'notstarted'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import overdueIssues from './Homepage/OverdueIssues.vue';
import IssuesInProgress from './Homepage/IssuesInProgress.vue';
import IssuesNotStartes from './Homepage/IssuesNotStartes.vue';
import todayIssues from './Homepage/TodayIssues.vue';
import futureIssues from './Homepage/FutureIssues.vue';
import MineWatched from './Homepage/MineWatched.vue';

export default {
  name: 'Home',
  components: {
    'overdue-issues': overdueIssues,
    'today-issues': todayIssues,
    'future-issues': futureIssues,
    'mine-watched': MineWatched,
    'issues-in-progress': IssuesInProgress,
    'issues-not-started': IssuesNotStartes,
  },
  data() {
    return {
      activeName: 'mine_watchers',
    };
  },
};
</script>
