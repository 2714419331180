<template>
  <dashboard-table
      :options="options"
      :source="source"
      :thead="selectThead"
  ></dashboard-table>
</template>
<script>
import actions from './actions';

export default {
  name: 'IssuesInProgress',
  mixins: [actions],
  data() {
    return {
      source: 'homepage/issues_in_progress',
    };
  },
};
</script>
